const merit = () => {
  $(function() {
    $('#course-tag-nav p').on('click', function () {
      if ($(this).not('active')) {
        // タブメニュー
        $(this).addClass('active').siblings('p').removeClass('active');
        // タブの中身
        var index = $('#course-tag-nav p').index(this);
        $('#course-tag-box .course-tag-box__inner').eq(index).addClass('active').siblings('.course-tag-box__inner').removeClass('active');
      }
    });

    var hash = location.hash;
    hash = (hash.match(/^#course\d+$/) || [])[0];
    if ($(hash).length) {
      var tagname = hash.slice(1);
    } else {
      var tagname = "course1";
    }
    $('#course-tag-nav p').removeClass('active');
    var tagno = $('div#course-tag-nav p#' + tagname).index();
    $('#course-tag-box .course-tag-box__inner').eq(tagno).addClass('active').siblings('.course-tag-box__inner').removeClass('active');
    $('div#course-tag-nav p').eq(tagno).addClass('active')
  });
};

export default merit();
