import device from "./modules/device";
import common from './modules/common';
import front from "./modules/front";
import merit from "./modules/merit";
import news from "./modules/news";
import modal from "./modules/modal";
import aos from './modules/aos';

export default {
  device,
  common,
  front,
  merit,
  news,
  modal,
  aos
};
