import "modaal";

const modal = () => {
  $(function() {
    $(".modal-gallery").modaal({
      type: "image",
      background: "#187166",
      overlay_opacity: 0.8
    });
  });

  $(function () {
    $(".modal-inline").modaal({
      type: "inline",
      background: "#187166",
      overlay_opacity: 0.8
    });
  });

  $(function () {
    $(".oc-schedule__item .week").each(function () {
      if ($(this).text().match('SAT')) {
        $(this).css('color', '#0c2e5c');
      } else if ($(this).text().match('SUN')) {
        $(this).css('color', '#a90f34');
      }
    });
  });
};

export default modal();