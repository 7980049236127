const common = () => {
  $(function() {
    var ua = navigator.userAgent.toLowerCase();
    var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);
    if (!isMobile) {
      $('a[href^="tel:"]').on("click", function(e) {
        e.preventDefault();
      });
    }

    if (
      navigator.userAgent.match(/MSIE 10/i) ||
      navigator.userAgent.match(/Trident\/7\./) ||
      navigator.userAgent.match(/Edge\/12\./)
    ) {
      $("body").on("mousewheel", function() {
        event.preventDefault();
        var wd = event.wheelDelta;
        var csp = window.pageYOffset;
        window.scrollTo(0, csp - wd);
      });
    }

    var currentWidth = 0;
    var timer = false;
    $(window).on("resize orientationchange", function (e) {
      if (currentWidth == window.innerWidth) {
        return;
      }
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        var wsw = window.outerWidth;
        if (wsw <= 767) {
          $("head").append($('<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, user-scalable=yes">'));
        } else if (wsw < 993) {
          $("head").append($('<meta name="viewport" content="width=993, user-scalable=yes">'));
        } else {
          $("head").append($('<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, user-scalable=yes">'));
        }
      }, 100);
      currentWidth = window.innerWidth;
    }).trigger("resize");

    $('a[href*="#"]').on("click", function() {
      if ($(this).hasClass('modal-inline')) {
        return false;
      }

      var w = $(window).width();
      var x = 768;
      if (w <= x) {
        var headerHight = 58;
      } else {
        var headerHight = 75;
      }
      var str = $(this).attr("href");

      var cut_str = "#";
      var index = str.indexOf(cut_str);
      var href = str.slice(index);

      if (href == "#") {
        var target = $("body");
        var position = $(target).offset().top - headerHight;
        $("html, body").animate({ scrollTop: position }, 500, "swing");
        return false;
      } else {
        // ページ内にリンク先がある
        if ($(href).length) {
          var target = href;
          var position = $(target).offset().top - headerHight;
          $("html, body").animate({ scrollTop: position }, 500, "swing");
          return false;
        }
      }
    });

    $(window).on("load", function() {
      var w = $(window).width();
      var x = 768;
      if (w <= x) {
        var headerHight = 58;
      } else {
        var headerHight = 75;
      }

      if (document.URL.match("#")) {
        var str = location.href;
        var cut_str = "#";
        var index = str.indexOf(cut_str);
        var href = str.slice(index);
        var target = href;
        var position = $(target).offset().top - headerHight;
        $("html, body").scrollTop(position);
        return false;
      }
    });

    var windowSize = $(window),
      header = $(".g-header"),
      gnavBottom;
    $(windowSize).on("scroll", function() {
      gnavBottom = $(".gnav-bottom").height();
      if (windowSize.scrollTop() > gnavBottom) {
        header.addClass("fixed");
      } else {
        header.removeClass("fixed");
      }
    });
    windowSize.trigger("scroll");

    $(".btn-menu").on("click", function() {
      $(this).toggleClass("js-open");
      $(".gnav").toggleClass("js-open");
      $("body").toggleClass("js-open");
    });

    $("#gnav__search").on("click", function() {
      $(this).toggleClass("active");
      $("#gnav__search-form")
        .slideToggle(150)
        .toggleClass("show");
    });

    function enter() {
      if (window.event.keyCode == 13) document.q.submit();
    }

    function checkHeader() {
      var bp = 1281;
      var x = $(window).width();
      if (x < bp) return true;
    }

    $(window).on("resize scroll", function () {
      var ua = navigator.userAgent;
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
        $(".g-header").css("left", 0);
        $(".gnav").css("left", 0);
      } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
        $(".g-header").css("left", 0);
        $(".gnav").css("left", 0);
      } else {
        if (!checkHeader()) {
          $(".g-header").css("left", 0);
          $(".gnav").css("left", 0);
        } else {
          $(".g-header").css("left", -$(window).scrollLeft());
          $(".gnav").css("left", -$(window).scrollLeft());
        }
      }
    });

    $(".gnav .switch").on("click", function() {
      if (!checkHeader()) {
      } else {
        $(this).toggleClass("active");
        $(this)
          .next(".gnav__second-list-wrap")
          .slideToggle(250);
      }
    });

    $(".gnav .switch2").on("click", function() {
      if (!checkHeader()) {
      } else {
        $(this).toggleClass("active");
        $(this)
          .next(".gnav__third-list-wrap")
          .slideToggle(250);
      }
    });

    $(window).on("resize", function() {
      if (!checkHeader()) {
        $("body").removeClass("js-open");
        $(".gnav").removeClass("js-open");
        $(".btn-menu").removeClass("js-open");
        $(".gnav .switch").removeClass("active");
        $(".gnav__second-list-wrap").removeAttr("style");
        $(".gnav .switch2").removeClass("active");
        $(".gnav__third-list-wrap").removeAttr("style");
      } else {
      }
    });
  });
};

export default common();
