const front = () => {
  $(function() {
    var movieRatio = 16 / 9;
    function movieAdjust() {
      setTimeout(function () {
      var adjustWidth = $(window).width();
      var adjustHeight = $(window).innerHeight();
      if (adjustHeight > adjustWidth / movieRatio) {
        adjustWidth = adjustHeight * movieRatio;
      }
      $('.front-mainv').css({ maxHeight: (adjustWidth / movieRatio) });
      $('.front-mainv iframe').css({ width: (adjustWidth), height: (adjustWidth / movieRatio) });
      }, 100);
    }
    $(window).on('load resize', function () {
      movieAdjust();
    });

    $('#tag-nav span').on('click', function () {
      if ($(this).not('active')) {
        // タブメニュー
        $(this).addClass('active').siblings('span').removeClass('active');
        // タブの中身
        var index = $('#tag-nav span').index(this);
        $('#tag-box .tag-box__inner').eq(index).addClass('active').siblings('.tag-box__inner').removeClass('active');
      }
    });

    var hash = location.hash;
    hash = (hash.match(/^#tag\d+$/) || [])[0];
    if ($(hash).length) {
      var tagname = hash.slice(1);
    } else {
      var tagname = "tag1";
    }
    $('#tag-nav span').removeClass('active');
    var tagno = $('div#tag-nav span#' + tagname).index();
    $('#tag-box .tag-box__inner').eq(tagno).addClass('active').siblings('.tag-box__inner').removeClass('active');
    $('div#tag-nav span').eq(tagno).addClass('active')

    $('.front-learnmore__main-item .title-wrap').on({
      'mouseenter': function() {
        $(this).prev('.image').addClass('active');
      },
      'mouseleave': function() {
        $(this).prev('.image').removeClass('active');
      }
    })
  });
};

export default front();
