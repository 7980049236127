const news = () => {
  $(function() {
    $(".m-sorter__box").on("click", function () {
      if ($(this).next().is(":visible")) {
        $(this).next().slideUp(300);
        $(this).removeClass("active");
      } else {
        $(this).next().slideDown(300);
        $(this).addClass("active");
      }
    });
  });
};

export default news();
