const device = () => {
  window.onload = () => {
    var ua = navigator.userAgent,
        bodyClass = document.body.classList;

    if (
      ua.indexOf("iPhone") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      bodyClass.add("sp");

      if (ua.indexOf("iPhone") > 0) bodyClass.add("iphone");
      if (ua.indexOf("Android") > 0) bodyClass.add("android");
    } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      bodyClass.add("tablet");

      if (ua.indexOf("iPad") > 0) bodyClass.add("ipad");
      if (ua.indexOf("Android") > 0) bodyClass.add("android");
    } else {
      bodyClass.add("pc");
    }
  };
};

export default device();
