import AOS from 'aos';

const aosInit = AOS.init({
  once: true,
  easing: "ease-in-out-quart",
  duration: 500,
  offset: 100
});

export default aosInit;
